import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "profile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileSection = _resolveComponent("ProfileSection")!
  const _component_SecondLayout = _resolveComponent("SecondLayout")!

  return (_openBlock(), _createBlock(_component_SecondLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ProfileSection)
      ])
    ]),
    _: 1
  }))
}
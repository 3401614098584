
import { defineComponent } from "vue";
import DefaultProfile from "@/assets/svg/default-profile.svg?inline";
import EmailVerif from "@/assets/svg/email-verif.svg?inline";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import AlertBlock from "@/components/molecules/AlertBlock.vue";

export default defineComponent({
  name: "ProfileSection",
  data() {
    const schema = yup.object().shape({
      name: yup.string().notRequired(),
      email: yup.string().notRequired(),
    });

    return {
      isModalOpen: false,
      schema,
      file: "",
      showPreview: false,
      imagePreview: "",
      defaultPhoto: true,
      avatar: {},
      data: {},
      formData: {
        accept: Boolean,
        subscribe: Boolean,
      },
      formDataToSend: {
        name: String,
        email: String,
      },
      error: {}
    };
  },
  components: {
    DefaultProfile,
    EmailVerif,
    Form,
    Field,
    ErrorMessage,
    AlertBlock
  },
  mounted() {
    this.formData.subscribe = this.data.news_subscription;
    this.formData.accept = this.data.my_collection_hidden;
  },
  watch: {
    formData: {
      deep: true,
      handler() {
        this.sendForm();
      },
    },
  },
  methods: {
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append("avatar", this.file);
      this.$store.dispatch("getNFTs/updateAvatar", formData).then(
        (response) => {
          this.avatar = response.data.avatar;
          this.defaultPhoto = false;
          this.sendForm();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    async sendForm() {
      const { formData } = this;
      const { formDataToSend } = this;
      if (formDataToSend.name == null || formDataToSend.name == "") {
        formDataToSend.name = "Name";
      }
      if (formDataToSend.email == null || formDataToSend.email == "") {
        formDataToSend.email = "example@gmail.com";
      }
      let data = {
        name: formDataToSend.name,
        email: formDataToSend.email,
        news_subscription: formData.subscribe,
        my_collection_hidden: formData.accept,
      };
      this.$store.dispatch("getNFTs/updateUser", data).then(
        (response) => {
          this.data = response.data;
          if (this.data != null) {
            (this.formDataToSend.name = this.data.name),
              (this.formDataToSend.email = this.data.email);
            (this.formData.accept = this.data.my_collection_hidden),
              (this.formData.subscribe = this.data.news_subscription);
            if (this.data.avatar == null) {
              this.defaultPhoto = true;
            } else this.defaultPhoto = false;
          }
          this.error = {}
        },
        (error) => {
          this.error = error.response.data.errors
        }
      );
    },
  },
});
